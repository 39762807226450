import styled from "styled-components";

export const CollectionPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 48px;
`
export const PanelRowTitle = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;

    h4 {
        background-color: rgba(255,255,255,0.2);
        border: 2px solid rgba(255,255,255,0.2);
        border-radius: 100px;
        color: #FFFFFF;
        cursor: pointer;
        padding: 4px 12px;

        &:hover {
            background-color: #FFFFFF;
            color: #000000;
        }
    }
`
export const OutletContainer = styled.div`
    width: ${(props) => props.$width};
`