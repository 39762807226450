// styles
import {
  ReleaseCalendarWidgetContainer,
  ReleaseGrid,
} from './ReleaseCalendarWidget.styles';

// assets
import rel_A_img from '../../../assets/releases/mlb/2024/topps/2024-topps-chrome-update-series-baseball.png';
import rel_B_img from '../../../assets/releases/mlb/2024/panini/2024-panini-impeccable-baseball.png';
import rel_C_img from '../../../assets/releases/mlb/2024/topps/2024-topps-holiday-baseball.png';
import rel_D_img from '../../../assets/releases/mlb/2024/bowman/2024-bowman-draft-baseball.png';

// components
import ReleasePanel from './components/release-panel/ReleasePanel';
import Title from '../../../components/widgets/components/title/Title';

export default function ReleaseCalendarWidget() {

  return (
    <ReleaseCalendarWidgetContainer className='widget-bg'>
      <Title title={"UPCOMING RELEASES"} />
      <ReleaseGrid>
        <ReleasePanel img={rel_A_img} imgAlt={"2024 Topps Chrome Update Series Baseball"} weekday={"WEDNESDAY"} day={13} month={"NOV"} year={2024}/>
        <ReleasePanel img={rel_B_img} imgAlt={"2024 Panini Impeccable Baseball"} weekday={"WEDNESDAY"} day={13} month={"NOV"} year={2024}/>
        <ReleasePanel img={rel_C_img} imgAlt={"2024 Topps Holiday Baseball"} weekday={"FRIDAY"} day={15} month={"NOV"} year={2024}/>
        <ReleasePanel img={rel_D_img} imgAlt={"2024 Bowman Draft Baseball"} weekday={"WEDNESDAY"} day={4} month={"DEC"} year={2024}/>
      </ReleaseGrid>
    </ReleaseCalendarWidgetContainer>
  )
}
