// styles
import {
    TitleContainer,
    BtnContainer
} from './Title.styles';

// components
import PrimaryBtn from '../../../btns/primary-btn/PrimaryBtn';

export default function Title({ children, title, btnPath = null }) {
    return (
        <TitleContainer>
            <h1>{title}</h1>
            {btnPath !== null &&
                <BtnContainer>
                    <PrimaryBtn title={btnPath.title} path={btnPath.path} />
                </BtnContainer>
            }
            { children }
        </TitleContainer>
    )
}
