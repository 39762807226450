import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectNewSelectionCardData, selectSelectedCardId } from "../../../store/showcase/showcase.selector";

// styles
import {
  ShowcaseContainer,
  TitleContainer
} from "./Showcase.styles";

export default function Showcase() {
  const selectedCardId = useSelector(selectSelectedCardId);
  const newSelectionCardData = useSelector(selectNewSelectionCardData);

  return (
    <ShowcaseContainer className='widget-bg'>
      <TitleContainer>
        {selectedCardId === null && newSelectionCardData === null &&
          <h1>SHOWCASE</h1>
        }
        {selectedCardId !== null &&
          <h1>ADD SHOWCASE CARD</h1>
        }
      </TitleContainer>
      <Outlet />
    </ShowcaseContainer>
  );
}