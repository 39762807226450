import { useDispatch, useSelector } from 'react-redux';
import { selectDreamTeamCardData_MLB, selectPreSelectedCardId, selectPreSelectedCardType, selectSelectedCardId } from '../../../../../store/showcase/showcase.selector';
import { setPreselectedCardId, setPreselectedCardType } from '../../../../../store/showcase/showcase.action';

// styles
import {
    DreamTeamContainer,
    FieldDisplayContainer,
    OutlineContainer,
    PlacedPlayerCard,
    CardGroupContainer,
    BullpenDisplayContainer,
    BullpenDisplay,
    BullpenCardsContainer
} from './DreamTeam.styles';

// assets
import fieldOutline from '../../../../../assets/system/diamond-outline.svg';

// components
import CardDisplay from '../card-display/CardDisplay';
import CardOptions from '../card-options/CardOptions';
import ShowcaseSelectionView from '../showcase-selection-view/ShowcaseSelectionView';

export default function DreamTeam() {
    const dispatch = useDispatch();
    const preSelectedCardId = useSelector(selectPreSelectedCardId);
    const preSelectedCardType = useSelector(selectPreSelectedCardType);
    const selectedCardId = useSelector(selectSelectedCardId);
    const dreamTeamCardData_MLB = useSelector(selectDreamTeamCardData_MLB);

    // Card Display Data
    const dreamTeamFieldPositions_MLB = {
        "field-DH": {
            id: "field-DH",
            label: "DH",
            position: { top: '75%', left: '65%' }
        },
        "field-P": {
            id: "field-P",
            label: "P",
            position: { top: '54%', left: '45%' }
        },
        "field-C": {
            id: "field-C",
            label: "C",
            position: { top: '75%', left: '45%' }
        },
        "field-1B": {
            id: "field-1B",
            label: "1B",
            position: { top: '54%', left: '70%' }
        },
        "field-2B": {
            id: "field-2B",
            label: "2B",
            position: { top: '42%', left: '57%' }
        },
        "field-3B": {
            id: "field-3B",
            label: "3B",
            position: { top: '54%', left: '21%' }
        },
        "field-SS": {
            id: "field-SS",
            label: "SS",
            position: { top: '42%', left: '33%' }
        },
        "field-LF": {
            id: "field-LF",
            label: "LF",
            position: { top: '30%', left: '20%' }
        },
        "field-CF": {
            id: "field-CF",
            label: "CF",
            position: { top: '25%', left: '45%' }
        },
        "field-RF": {
            id: "field-RF",
            label: "RF",
            position: { top: '30%', left: '72%' }
        }
    };
    const dreamTeamRotationPositions_MLB = {
        "rotation-SP1": {
            id: "rotation-SP1",
            label: "SP"
        },
        "rotation-SP2": {
            id: "rotation-SP2",
            label: "SP"
        },
        "rotation-SP3": {
            id: "rotation-SP3",
            label: "SP"
        },
        "rotation-SP4": {
            id: "rotation-SP4",
            label: "SP"
        },
        "rotation-SP5": {
            id: "rotation-SP5",
            label: "SP"
        }
    };
    const dreamTeamBullpenPositions_MLB = {
        "bullpen-LRP1": {
            id: "bullpen-LRP1",
            label: "LRP"
        },
        "bullpen-LRP2": {
            id: "bullpen-LRP2",
            label: "LRP"
        },
        "bullpen-MRP1": {
            id: "bullpen-MRP1",
            label: "MRP"
        },
        "bullpen-MRP2": {
            id: "bullpen-MRP2",
            label: "MRP"
        },
        "bullpen-SU1": {
            id: "bullpen-SU1",
            label: "SU"
        },
        "bullpen-SU2": {
            id: "bullpen-SU2",
            label: "SU"
        },
        "bullpen-CL": {
            id: "bullpen-CL",
            label: "CL"
        }
    };

    const handleCardPreselection = (cardType, id) => {
        dispatch(setPreselectedCardId(id));
        dispatch(setPreselectedCardType(cardType));
    }

    return (
        <DreamTeamContainer>
            {selectedCardId === null &&
                <FieldDisplayContainer>
                    {preSelectedCardType !== "bullpen" ?
                        <>
                            <OutlineContainer>
                                <img src={fieldOutline} alt='field outline' />
                            </OutlineContainer>
                            {Object.values(dreamTeamFieldPositions_MLB).map(({ id, label, position }) => (
                                <PlacedPlayerCard
                                    key={id}
                                    style={{ top: position.top, left: position.left }}
                                >
                                    <CardDisplay
                                        key={id}
                                        id={id}
                                        positionLabel={label}
                                        isActive={preSelectedCardId === id}
                                        onClick={() => handleCardPreselection("field", id)}
                                    />
                                </PlacedPlayerCard>
                            ))}
                        </>
                        :
                        <CardOptions />
                    }
                </FieldDisplayContainer>
            }
            {selectedCardId === null &&
                <BullpenDisplayContainer>
                    {preSelectedCardType !== "field" ?
                        <BullpenDisplay>
                            <CardGroupContainer>
                                <h2>STARTING ROTATION</h2>
                                <BullpenCardsContainer>
                                    {Object.entries(dreamTeamRotationPositions_MLB).map(([id, { label }]) => (
                                        <CardDisplay
                                            key={id}
                                            id={id}
                                            positionLabel={label}
                                            isActive={preSelectedCardId === id}
                                            onClick={() => handleCardPreselection("bullpen", id)}
                                        />
                                    ))}
                                </BullpenCardsContainer>
                            </CardGroupContainer>
                            <CardGroupContainer>
                                <h2>BULLPEN</h2>
                                <BullpenCardsContainer>
                                    {Object.entries(dreamTeamBullpenPositions_MLB).map(([id, { label }]) => (
                                        <CardDisplay
                                            key={id}
                                            id={id}
                                            positionLabel={label}
                                            isActive={preSelectedCardId === id}
                                            onClick={() => handleCardPreselection("bullpen", id)}
                                        />
                                    ))}
                                </BullpenCardsContainer>
                            </CardGroupContainer>
                        </BullpenDisplay>
                        :
                        <CardOptions />
                    }
                </BullpenDisplayContainer>
            }
            {selectedCardId !== null &&
                <ShowcaseSelectionView cardData={dreamTeamCardData_MLB[selectedCardId]} />
            }
        </DreamTeamContainer>
    )
}
