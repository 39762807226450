import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { addChecklistToFirestore, addParallelsToFirestore } from "../../utils/firebase/firebase.utils.js";
import { setSystemNotification } from "../../store/system/system.action.js";

// CHECKLISTS
import { MLB_2022_TOPPS_US } from "../../data/checklist-data/mlb/2022/topps/MLB_2022_TOPPS_US.js";
import { MLB_2024_TOPPS_CHROME } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_CHROME.js";
import { MLB_2024_TOPPS_CHROME_UPDATE_SERIES } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_CHROME_UPDATE_SERIES.js";
import { MLB_2024_TOPPS_SERIES1 } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_S1.js";
import { MLB_2024_TOPPS_SERIES2 } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_S2.js";
import { MLB_2024_TOPPS_US } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_US.js";
import { SINGULAR_UPLOAD_CHECKLIST } from "../../data/checklist-data/singular-uploads/SINGULAR_UPLOADS.js";

// PARALLELS
import { MLB_2022_TOPPS_US_PARALLELS } from "../../data/checklist-data/mlb/2022/topps/MLB_2022_TOPPS_US.js";
import { MLB_2024_TOPPS_CHROME_PARALLELS } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_CHROME.js";
import { MLB_2024_TOPPS_CHROME_UPDATE_SERIES_PARALLELS } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_CHROME_UPDATE_SERIES.js";
import { MLB_2024_TOPPS_SERIES1_PARALLELS } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_S1.js";
import { MLB_2024_TOPPS_SERIES2_PARALLELS } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_S2.js";
import { MLB_2024_TOPPS_US_PARALLELS } from "../../data/checklist-data/mlb/2024/topps/MLB_2024_TOPPS_US.js";
import { SINGULAR_UPLOAD_PARALLELS } from "../../data/checklist-data/singular-uploads/SINGULAR_UPLOADS.js";

// styles
import {
    ChecklistManagerContainer,
    BtnContainer,
    JsonDisplays,
    JsonDisplayContainer
} from "./ChecklistManager.styles.js";

// components
import Filter from '../../components/widgets/components/filter/Filter.js';
import Title from "../../components/widgets/components/title/Title.js";
import PrimaryBtn from "../../components/btns/primary-btn/PrimaryBtn.js";

export default function ChecklistManager() {
    const dispatch = useDispatch();
    const [activeData, setActiveData] = useState(0);

    const TEST_CHECKLIST_DATA = useMemo(() => [
        {
            title: "2024 MLB Topps Chrome",
            checklist: MLB_2024_TOPPS_CHROME,
            parallels: MLB_2024_TOPPS_CHROME_PARALLELS
        },
        {
            title: "2024 MLB Topps Chrome Update Series",
            checklist: MLB_2024_TOPPS_CHROME_UPDATE_SERIES,
            parallels: MLB_2024_TOPPS_CHROME_UPDATE_SERIES_PARALLELS
        },
        {
            title: "2024 MLB Topps Series 1",
            checklist: MLB_2024_TOPPS_SERIES1,
            parallels: MLB_2024_TOPPS_SERIES1_PARALLELS
        },
        {
            title: "2024 MLB Topps Series 2",
            checklist: MLB_2024_TOPPS_SERIES2,
            parallels: MLB_2024_TOPPS_SERIES2_PARALLELS
        },
        {
            title: "2024 MLB Topps Update Series",
            checklist: MLB_2024_TOPPS_US,
            parallels: MLB_2024_TOPPS_US_PARALLELS
        },
        {
            title: "2022 MLB Topps Update Series",
            checklist: MLB_2022_TOPPS_US,
            parallels: MLB_2022_TOPPS_US_PARALLELS
        },
        {
            title: "SINGULAR UPLOAD",
            checklist: SINGULAR_UPLOAD_CHECKLIST,
            parallels: SINGULAR_UPLOAD_PARALLELS
        }
    ], []);

    const handleChange = (event) => {
        setActiveData(event.target.value);
    };

    const handleChecklistUpload = () => {
        const { checklist } = TEST_CHECKLIST_DATA[activeData];
        try {
            addChecklistToFirestore(checklist);
            dispatch(setSystemNotification("Successfully added checklist!"));
        } catch (error) {
            console.error("Error uploading data: ", error);
        }
    };

    const handleParallelsUpload = () => {
        const { parallels } = TEST_CHECKLIST_DATA[activeData];
        try {
            addParallelsToFirestore(parallels);
            dispatch(setSystemNotification("Successfully added checklist parallels!"));
        } catch (error) {
            console.error("Error uploading data: ", error);
        }
    }

    return (
        <ChecklistManagerContainer>
            <Title title={"CHECKLIST MANAGER"}>
                <Filter value={activeData} onChange={handleChange}>
                    {TEST_CHECKLIST_DATA.map((item, index) => (
                        <option key={index} value={index}>
                            {item.title}
                        </option>
                    ))}
                </Filter>
            </Title>
            <JsonDisplays>
                <JsonDisplayContainer>
                    <h4>Checklist Data</h4>
                    <BtnContainer onClick={handleChecklistUpload}>
                        <PrimaryBtn title={"UPLOAD CHECKLIST"} />
                    </BtnContainer>
                    <pre>{JSON.stringify(TEST_CHECKLIST_DATA[activeData].checklist, null, 2)}</pre>
                </JsonDisplayContainer>
                <JsonDisplayContainer>
                    <h4>Parallel Data</h4>
                    <BtnContainer onClick={handleParallelsUpload}>
                        <PrimaryBtn title={"UPLOAD PARALLELS"} />
                    </BtnContainer>
                    <pre>{JSON.stringify(TEST_CHECKLIST_DATA[activeData].parallels, null, 2)}</pre>
                </JsonDisplayContainer>
            </JsonDisplays>
        </ChecklistManagerContainer>
    );
}