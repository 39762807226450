import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPreselectedCardId, setPreselectedCardType, setSelectedCardId } from '../../../../../store/showcase/showcase.action';
import { selectNewSelectionCardData } from '../../../../../store/showcase/showcase.selector';

// styles
import {
    ShowcaseSelectionViewContainer,
    BtnContainer
} from './ShowcaseSelectionView.styles';

// components
import CardDisplayLarge from '../card-display-large/CardDisplayLarge';
import PrimaryBtn from '../../../../btns/primary-btn/PrimaryBtn';

export default function ShowcaseSelectionView() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const newSelectionCardData = useSelector(selectNewSelectionCardData);

    const handleBack = () => {
        if (newSelectionCardData) {
            alert("addingNewCard is true")
            navigate(-1);
        } else {
            alert("addingNewCard is false")
            dispatch(setSelectedCardId(null));
            dispatch(setPreselectedCardType(null));
            dispatch(setPreselectedCardId(null));
            navigate("/")
        }
    }

    return (
        <ShowcaseSelectionViewContainer>
            <BtnContainer onClick={handleBack} >
                <PrimaryBtn title={"BACK"} />
            </BtnContainer>
            <CardDisplayLarge size={"L"} posIsDisplayed={false} cardData={newSelectionCardData} />
        </ShowcaseSelectionViewContainer>
    );
}