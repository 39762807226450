import styled from "styled-components";

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
`
export const MainGradientBGContainer = styled.div`
    height: 100vh;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: -500;
`
export const NavContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 24px;
    padding: 0px 48px;
    margin-bottom: 24px;
`
export const NavItem = styled.h3`
    cursor: pointer;
    font-size: 16px;
    font-weight: ${(props) => props.$isActive ? '700' : '600'};
    opacity: ${(props) => props.$isActive ? '1.0' : '0.4'};
    padding: 8px;
    position: relative;
    transition: 0.3s ease-in-out;

    &::after {
        background-color: #FFFFFF;
        bottom: 0px;
        content: "";
        height: 2px;
        left: 50%;
        position: absolute;     
        transform: translateX(-50%);
        transition: width 0.2s ease-in-out;
        width: ${(props) => props.$isActive ? '100%' : '0%'};
    }

    &:hover {
        opacity: 1.0;
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
    }
`