import styled from "styled-components";

export const ReleasePanelContainer = styled.div`
    align-items: start;
    cursor: pointer;
    display: flex;
    flex: 0 0 calc(25% - 12px);
    height: 160px;
    justify-content: space-between;
    position: relative;
    width: 25%;
    transition: 0.3s ease-in-out;

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    &:hover {
        transform: scale(1.025);
        transition: 0.3s ease-in-out;
}
`
export const ImgDisplay = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 160px;
    
    img {
        height: 100%;
        max-width: 200px;
    }
`
export const DateDisplay = styled.div`
    align-items: end;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 160px;

    h2 {
        font-size: 20px;
        font-weight: 600;
    }
`
export const DateDetails = styled.div`
    align-items: end;
    display: flex;
    gap: 8px;

    h1 {
        font-size: 32px;
        font-weight: 300;
        line-height: 24px;
        opacity: 1.0;
    }
`
export const DateDetailsVert = styled.div`
    align-items: end;
    display: flex;
    flex-direction: column;
    gap: 4px;

    h4 {
        font-size: 10px;
        font-weight: 600;
        line-height: 10px;
    }
`