import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { selectTeamIndex, selectUser } from '../../../../store/user/user.selector';
import { useLogOut } from '../../../../hooks/useLogOut.js';

// data
import { TEAM_LOGOS } from '../../../../data/theme-data/team-data.js';

// styles
import { HeaderProfilePillContainer, DropdownMenu, ProfileWrapper, MenuContent } from './HeaderProfilePill.styles.js';

export default function HeaderProfilePill() {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const teamIndex = useSelector(selectTeamIndex);
  const profilePillRef = useRef(null);
  const dropdownMenuRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const { logOut } = useLogOut();

  const listItems = [
    { label: 'Account', path: '/account' },
    { label: 'Log Out', action: logOut }
  ];

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };
  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profilePillRef.current &&
        !profilePillRef.current.contains(event.target) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isExpanded]);

  const handleNavigate = (route) => {
    navigate(route);
    setIsExpanded(false);
  }

  return (
    <ProfileWrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <HeaderProfilePillContainer ref={profilePillRef}>
        {teamIndex && TEAM_LOGOS[teamIndex].logo &&
          <img src={TEAM_LOGOS[teamIndex].logo} alt={TEAM_LOGOS[teamIndex].name} />
        }
        <h3>@{user.displayName}</h3>
      </HeaderProfilePillContainer>
      <AnimatePresence>
        {isExpanded && (
          <DropdownMenu
            ref={dropdownMenuRef}
            className='panel-bg'
            initial={{ opacity: 0, y: 8 }}
            animate={{ opacity: isExpanded ? 1 : 0, y: isExpanded ? 0 : -8 }}
            exit={{ opacity: 0, y: 4 }}
            transition={{ duration: 0.2 }}
          >
            <MenuContent>
              <div />
              <ul>
                {listItems.map((item, index) => (
                  <motion.li
                    key={item.label}
                    onClick={() => item.path ? handleNavigate(item.path) : item.action?.()}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: index * 0.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{ cursor: "pointer", listStyle: "none" }}
                  >
                    {item.label}
                  </motion.li>
                ))}
              </ul>
            </MenuContent>
          </DropdownMenu>
        )}
      </AnimatePresence>
    </ProfileWrapper>
  );
};