import { SHOWCASE_ACTION_TYPES } from './showcase.types';

export const setPreselectedCardId = (cardId) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_ID, payload: cardId }
}
export const setPreselectedCardType = (cardType) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_TYPE, payload: cardType }
}
export const setSelectedCardId = (cardId) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_SELECTED_CARD_ID, payload: cardId }
}
export const setNewSelectionCardData = (data) => {
    return { type: SHOWCASE_ACTION_TYPES.SET_NEW_SELECTION_CARD_DATA, payload: data }
}