import styled from "styled-components";

export const FilterContainer = styled.select`
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: 600;
    padding: 12px 8px;
    position: relative;
    width: 240px;
    font-size: 12px;
    
    appearance: none; /* Remove the default dropdown arrow */
    -webkit-appearance: none;
    -moz-appearance: none;
    
    &:focus {
        outline: none;
    }
`;