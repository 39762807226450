import styled from "styled-components";
import { motion } from "framer-motion";

export const CardDisplayContainer = styled(motion.div)`
    align-items: center;
    background: rgba(255, 255, 255, 0.25);
    border: ${props => props.$isActive ? "2px solid #FFFFFF" : "none"};
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 88px;
    justify-content: center;
    position: relative;
    width: 64px;
`
export const ImgContainer = styled.div`
    align-items: center;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    img {
        opacity: 0.4;
        width: 40%;
    }
`
export const PositionContainer = styled.div`
    bottom: -24px;
    font-weight: 500;
    position: absolute;
`