import { useNavigate, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectQueuedCardsDisplay } from '../../store/card/card.selector';

// styles
import {
    CollectionPageContainer,
    PanelRowTitle,
    OutletContainer
} from './CollectionPage.styles';

// components
import Title from '../../components/widgets/components/title/Title';

export default function CollectionPage() {
    const navigate = useNavigate();
    const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();
    const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);

    const handleNavigate = (level) => {
        let path = '/collection';

        switch (level) {
            case 'sport':
                path = '/collection';
                break;
            case 'year':
                path = `/collection/${sport}`;
                break;
            case 'manufacturer':
                path = `/collection/${sport}/${year}`;
                break;
            case 'series':
                path = `/collection/${sport}/${year}/${manufacturer}`;
                break;
            case 'cardType':
                path = `/collection/${sport}/${year}/${manufacturer}/${series}`;
                break;
            case 'cardTypeCategory':
                path = `/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}`;
                break;
            default:
                break;
        }
        navigate(path);
    };

    return (
        <CollectionPageContainer>
            <Title title={"COLLECTION"} btnPath={!sport ? { title: "+ ADD CARD", path: "/add" }: null} />
            {sport &&
                <PanelRowTitle>
                    {sport && (
                        <h4 onClick={() => handleNavigate('sport')}>{sport}</h4>
                    )}
                    {year && (
                        <h4 onClick={() => handleNavigate('year')}>{year}</h4>
                    )}
                    {manufacturer && (
                        <h4 onClick={() => handleNavigate('manufacturer')}>{manufacturer}</h4>
                    )}
                    {series && (
                        <h4 onClick={() => handleNavigate('series')}>{series}</h4>
                    )}
                    {cardType && (
                        <h4 onClick={() => handleNavigate('cardType')}>{cardType}</h4>
                    )}
                    {cardTypeCategory && (
                        <h4 onClick={() => handleNavigate('cardTypeCategory')}>{cardTypeCategory}</h4>
                    )}
                </PanelRowTitle>
            }
            <OutletContainer $width={queuedCardsDisplay.length !== 0 ? '60%' : '100%'}>
                <Outlet />
            </OutletContainer>
        </CollectionPageContainer>
    )
}
