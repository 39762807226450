import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAvailableCardTypes } from '../../../utils/firebase/firebase.utils';
import { selectUser } from '../../../store/user/user.selector';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function CardTypeSelection({ type }) {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { sport, year, manufacturer, series } = useParams();
  const [availableCardTypes, setAvailableCardTypes] = useState([]);

  useEffect(() => {
    const fetchCardTypes = async () => {
      const fetchedCardTypes = type === "add" ? await getAvailableCardTypes(null, sport, year, manufacturer, series) : await getAvailableCardTypes(user.uid, sport, year, manufacturer, series);
      setAvailableCardTypes(fetchedCardTypes);
    };

    fetchCardTypes();
  }, [type, user, sport, year, manufacturer, series]);

  const basePath = `/${sport}/${year}/${manufacturer}/${series}`;

  let selectedBasePath;
  if (type === "select") {
    selectedBasePath = `/showcase-add${basePath}`;
  } else if (type === "add") {
    selectedBasePath = `/add${basePath}`;
  } else if (type === "view") {
    selectedBasePath = `/collection${basePath}`;
  }

  const displayData = availableCardTypes
    .sort((a, b) => a.cardType.localeCompare(b.cardType))
    .map(cardType => ({
      name: cardType.cardType,
      count: cardType.totalCardCount
  }));

  const handleItemClick = () => {
    navigate(selectedBasePath);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={selectedBasePath} />;
}