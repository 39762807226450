import PropTypes from 'prop-types';

// styles
import {
    CardDisplayContainer,
    ImgContainer,
    PositionContainer
} from './CardDisplay.styles';

// assets
import pullVaultIcon from '../../../../../assets/system/cardvault-symbol.png';

// components
import CardAttribute from '../../../../card-attribute/CardAttribute';

export default function CardDisplay({ isActive, positionLabel, onClick }) {
    return (
        <CardDisplayContainer
            $isActive={isActive}
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 240, damping: 20 }}
        >
            <ImgContainer>
                <img src={pullVaultIcon} alt="no card" />
            </ImgContainer>
            <PositionContainer>
                <CardAttribute text={positionLabel} />
            </PositionContainer>
        </CardDisplayContainer>
    );
}

CardDisplay.propTypes = {
    id: PropTypes.string.isRequired,
    positionLabel: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};