import { Outlet } from 'react-router-dom';

// styles
import {
  HomePageContainer,
} from './HomePage.styles.js';

// components
import ReleaseCalendarWidget from '../../components/widgets/release-calendar/ReleaseCalendarWidget.js';
// import Showcase from '../../components/widgets/showcase/Showcase.js';


export default function HomePage() {

  return (
    <HomePageContainer>
      <ReleaseCalendarWidget />
      <Outlet />
    </HomePageContainer>
  );
}