import {
    ReleasePanelContainer,
    ImgDisplay,
    DateDisplay,
    DateDetails,
    DateDetailsVert
} from './ReleasePanel.styles';

export default function ReleasePanel({ img, imgAlt, weekday, day, month, year, path = null }) {
    return (
        <ReleasePanelContainer className='widget-bg'>
            <a href={path || "https://www.google.com"} target="_blank" rel="noopener noreferrer">
                <ImgDisplay>
                    <img src={img} alt={imgAlt} />
                </ImgDisplay>
                <DateDisplay>
                    <h2>{weekday}</h2>
                    <DateDetails>
                        <DateDetailsVert>
                            <h4>{month}</h4>
                            <h4>{year}</h4>
                        </DateDetailsVert>
                        <h1>{day}</h1>
                    </DateDetails>
                </DateDisplay>
            </a>
        </ReleasePanelContainer>
    );
}