import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAvailableManufacturers } from '../../../utils/firebase/firebase.utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function ManufacturerSelection({ type }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { sport, year } = useParams();
  const [availableManufacturers, setAvailableManufacturers] = useState([]);

  useEffect(() => {
    const fetchManufacturers = async () => {
      const manufacturers = type === "add" ? await getAvailableManufacturers(null, sport, year) : await getAvailableManufacturers(user.uid, sport, year);
      setAvailableManufacturers(manufacturers);
    };

    fetchManufacturers();
  }, [type, user.uid, sport, year]);


  const basePath = `/${sport}/${year}`;

  let selectedBasePath;
  if (type === "select") {
    selectedBasePath = `/showcase-add${basePath}`;
  } else if (type === "add") {
    selectedBasePath = `/add${basePath}`;
  } else if (type === "view") {
    selectedBasePath = `/collection${basePath}`;
  }

  const displayData = availableManufacturers
    .sort((a, b) => a.manufacturer.localeCompare(b.manufacturer))
    .map(manufacturer => ({
      name: manufacturer.manufacturer,
      count: manufacturer.totalCardCount
  }));

  const handleItemClick = () => {
    navigate(selectedBasePath);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={selectedBasePath} />;
}