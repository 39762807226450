import styled from "styled-components";

export const ActivityLogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0px 48px;
`
export const ActivityLogDisplay = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 48px;

    h3 {
        color: #FFFFFF;
        font-size: 10px;
        font-weight: 500;
    }

    p {
        font-weight: 600;
        margin-top: 24px;
        text-align: center;
    }
`
