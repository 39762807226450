import styled from "styled-components";

export const ShowcaseSelectionViewContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 48px;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
`
export const BtnContainer = styled.div`
    left: 0;
    position: absolute;
    top: 64px;
`