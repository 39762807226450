export const SINGULAR_UPLOAD_CHECKLIST = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball Update Series": {
                    "Autograph": {
                        "Topps Chrome Update Autograph Cards": {
                            "AC-CME": {
                                "cardNumber": "AC-CME",
                                "name": ["Curtis Mead"],
                                "team": ["Tampa Bay Rays™"],
                                "cardAttributes": ["Rookie"]
                            }
                        }
                    }
                }
            }
        }
    }

}

export const SINGULAR_UPLOAD_PARALLELS = {
    "MLB": {
        "2024": {
            "Topps": {
                "Topps Chrome® Baseball Update Series": {
                    "Base Card Set": {
                        // // "Base": [
                        // //     { "name": "RED", "color": "#FF0000", "limitedTo": 75 },
                        // //     { "name": "BLACK", "color": "#000000", "limitedTo": 25 },
                        // //     { "name": "PLATINUM", "color": "#E5E4E2", "limitedTo": 1 }
                        // ],
                    }
                }
            }
        }
    }
}