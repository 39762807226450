import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAvailableCardTypeCategories } from '../../../utils/firebase/firebase.utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function CardTypeCategorySelection({ type }) {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { sport, year, manufacturer, series, cardType } = useParams();
  const [availableCardTypeCategories, setAvailableCardTypeCategories] = useState([]);

  useEffect(() => {
    const fetchCardTypeCategories = async () => {
      const fetchedCardTypeCategories = type === "add" ? await getAvailableCardTypeCategories(null, sport, year, manufacturer, series, cardType) : await getAvailableCardTypeCategories(user.uid, sport, year, manufacturer, series, cardType);
      setAvailableCardTypeCategories(fetchedCardTypeCategories);
    };

    fetchCardTypeCategories();
  }, [type, user, sport, year, manufacturer, series, cardType]);

  const basePath = `/${sport}/${year}/${manufacturer}/${series}/${cardType}`;

  let selectedBasePath;
  if (type === "select") {
    selectedBasePath = `/showcase-add${basePath}`;
  } else if (type === "add") {
    selectedBasePath = `/add${basePath}`;
  } else if (type === "view") {
    selectedBasePath = `/collection${basePath}`;
  }

  const displayData = Object.keys(availableCardTypeCategories)
    .sort()
    .map(key => ({
      name: key,
      count: availableCardTypeCategories[key].cardCount
  }));

  const handleItemClick = () => {
    navigate(selectedBasePath);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={selectedBasePath} />;
}