import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectDreamTeamCardData_MLB, selectPreSelectedCardId } from '../../../../../store/showcase/showcase.selector';
import { setSelectedCardId, setPreselectedCardType, setPreselectedCardId } from '../../../../../store/showcase/showcase.action';
import { fadeInUp } from '../../../../../animations/Animations';

// styles
import {
    CardOptionsContainer,
    BtnContainer
} from './CardOptions.styles';

// components
import PrimaryBtn from '../../../../btns/primary-btn/PrimaryBtn';


export default function CardOptions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const preSelectedCardId = useSelector(selectPreSelectedCardId);
    const dreamTeamCardData_MLB = useSelector(selectDreamTeamCardData_MLB);

    const handleCardSelection = (id) => {
        dispatch(setSelectedCardId(id));
        dispatch(setPreselectedCardType(null));
        dispatch(setPreselectedCardId(null));
    }

    const handlePreSelectionCancel = () => {
        dispatch(setPreselectedCardType(null));
        dispatch(setPreselectedCardId(null));
    }

    return (
        <CardOptionsContainer>
            {dreamTeamCardData_MLB[preSelectedCardId].name !== null &&
                <BtnContainer
                    onClick={() => handleCardSelection(preSelectedCardId)}
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    custom={0 * 0.05}
                >
                    <PrimaryBtn title={"VIEW"} />
                </BtnContainer>
            }
            {dreamTeamCardData_MLB[preSelectedCardId].name !== null &&
                <BtnContainer
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    custom={1 * 0.05}
                >
                    <PrimaryBtn title={"CHANGE POSITION"} />
                </BtnContainer>
            }
            {dreamTeamCardData_MLB[preSelectedCardId].name !== null &&
                <BtnContainer
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    custom={2 * 0.05}
                >
                    <PrimaryBtn title={"REPLACE CARD"} />
                </BtnContainer>
            }
            {dreamTeamCardData_MLB[preSelectedCardId].name !== null &&
                <BtnContainer
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    custom={3 * 0.05}
                >
                    <PrimaryBtn title={"REMOVE"} />
                </BtnContainer>
            }
            {dreamTeamCardData_MLB[preSelectedCardId].name === null &&
                <BtnContainer
                    onClick={() => navigate("/showcase-add")}
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUp}
                    custom={3 * 0.05}
                >
                    <PrimaryBtn title={"ADD CARD"} />
                </BtnContainer>
            }
            <BtnContainer
                onClick={handlePreSelectionCancel}
                initial="hidden"
                animate="visible"
                variants={fadeInUp}
                custom={4 * 0.05}
            >
                <PrimaryBtn title={"BACK"} />
            </BtnContainer>
        </CardOptionsContainer>
    )
}
