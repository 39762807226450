import { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCardData, selectChecklistData, selectQueuedCardsDisplay } from '../../store/card/card.selector.js';
import { db, fetchChecklistData, fetchCollectionData } from '../../utils/firebase/firebase.utils.js';
import { collection, query, getDocs, limit } from 'firebase/firestore';
import { selectUser } from '../../store/user/user.selector.js';
import { setSystemNotification } from '../../store/system/system.action.js';

// styles
import {
    SeriesDisplayContainer,
    CollectionStats,
    DisplayHeader,
    ChecklistControls,
    ControlContent,
    BodyContainer,
    CardsDisplayContainer,
    CardsDisplay,
    InputField,
    ToggleContainer,
    ToggleSwitch,
    ToggleCircle,
    QueuedCardsContainer
} from './SeriesDisplay.styles.js';

// components
import ChecklistItemDisplay from '../checklist-item-display/ChecklistItemDisplay.js';
import QueuedCards from '../queued-cards/QueuedCards.js';
import StatBox from '../stat-box/StatBox.js';

export default function SeriesDisplay({ showcaseView = false }) {
    const dispatch = useDispatch();
    const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();
    const cardData = useSelector(selectCardData);
    const checklistData = useSelector(selectChecklistData);
    const user = useSelector(selectUser);
    const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);
    const [enteredSearchTerm, setEnteredSearchTerm] = useState('');
    const [activeCardNumber, setActiveCardNumber] = useState(null);
    const [availableParallels, setAvailableParallels] = useState([]);
    const [isToggled, setIsToggled] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState('All Teams');
    const [teams, setTeams] = useState([]);
    const [selectedStat, setSelectedStat] = useState("TotalCards");
    const [isFixed, setIsFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 475) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const teamFilteredStats = useMemo(() => {
        let totalTeamCards = 0;
        let collectedTeamCards = 0;
        let totalCollectedTeamCards = 0;
        let parallelCardsCount = 0;
        let numberedCardsCount = 0;
        let duplicatesCount = 0;

        // Iterate over checklistData and cardData for the selected team filter
        Object.keys(checklistData).forEach(cardNumberKey => {
            const checklistCard = checklistData[cardNumberKey];
            const collectedCard = cardData[cardNumberKey];

            const isTeamMatched = selectedTeam === 'All Teams' || (checklistCard.team && checklistCard.team.includes(selectedTeam));

            if (isTeamMatched) {
                totalTeamCards++;

                // Check if card is collected
                if (collectedCard) {
                    collectedTeamCards++

                    collectedCard.collectedCards.forEach((parallel) => {
                        totalCollectedTeamCards += parallel.cardQty;
                    });

                    // Count parallel cards
                    if (collectedCard.collectedCards.some(card => card.cardParallelName !== "BASE")) {
                        parallelCardsCount++;
                    }

                    // Count numbered cards
                    if (collectedCard.collectedCards.some(card => card.cardPrintNumber)) {
                        numberedCardsCount++;
                    }

                    // Count duplicates for each parallel in collectedCards
                    if (collectedCard) {
                        collectedCard.collectedCards.forEach((parallel) => {
                            if (parallel.cardQty > 1) {
                                duplicatesCount++; // Increment for each parallel with a quantity greater than 1
                            }
                        });
                    }
                }
            }
        });

        return {
            totalTeamCards,
            collectedTeamCards,
            totalCollectedTeamCards,
            parallelCardsCount,
            numberedCardsCount,
            duplicatesCount
        };
    }, [cardData, checklistData, selectedTeam]);

    const completionPercentage = teamFilteredStats.totalTeamCards > 0
        ? Math.round((teamFilteredStats.collectedTeamCards / teamFilteredStats.totalTeamCards) * 100)
        : 0;

    // Fetch Parallel data
    useEffect(() => {
        const fetchAvailableParallels = async () => {
            const cardsSubcollectionRef = collection(db, "checklists", sport, year, manufacturer, series, cardType, cardTypeCategory);
            const q = query(cardsSubcollectionRef, limit(1));

            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    const fieldValue = doc.data().availableParallels;
                    setAvailableParallels(fieldValue || []);
                } else {
                    setAvailableParallels([]);
                }
            } catch (error) {
                dispatch(setSystemNotification("Error fetching card parallels."))
                console.log("Error fetching available parallels:", error);
            }
        };

        fetchAvailableParallels();
    }, [dispatch, sport, year, manufacturer, series, cardType, cardTypeCategory]);

    // Fetch collection and checklist data
    useEffect(() => {
        if (user && user.uid) {
            const collectionPath = `users/${user.uid}/collection/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}`;
            const checklistPath = `checklists/${sport}/${year}/${manufacturer}/${series}/${cardType}/${cardTypeCategory}`;
            dispatch(fetchCollectionData(collectionPath));
            dispatch(fetchChecklistData(checklistPath));
        }
    }, [user, dispatch, sport, year, manufacturer, series, cardType, cardTypeCategory]);

    // Step 1: Extract unique teams from cardData and checklistData
    useEffect(() => {
        const allTeams = new Set();

        // Extract teams from cardData
        Object.keys(cardData).forEach(cardKey => {
            const card = cardData[cardKey];
            if (card.team) {
                card.team.forEach(team => allTeams.add(team));
            }
        });

        // Extract teams from checklistData
        Object.keys(checklistData).forEach(cardKey => {
            const card = checklistData[cardKey];
            if (card.team) {
                card.team.forEach(team => allTeams.add(team));
            }
        });

        // Convert Set to array and sort alphabetically
        setTeams(['All Teams', ...Array.from(allTeams).sort()]);
    }, [cardData, checklistData]);

    // Step 2: Memoize search and filtering logic
    const filteredCards = useMemo(() => {
        const filterAndSortCards = (cards) => {
            return cards
                .filter(card => {
                    const matchesTeam = selectedTeam === 'All Teams' || (card.team && card.team.includes(selectedTeam));

                    // Search filter: match enteredSearchTerm with card number or any player name in card.name array
                    const matchesSearch = enteredSearchTerm === '' ||
                        card.cardNumber.toLowerCase().includes(enteredSearchTerm.toLowerCase()) ||
                        (card.name && card.name.some(name => name.toLowerCase().includes(enteredSearchTerm.toLowerCase())));

                    if (!matchesSearch || !matchesTeam) {
                        return false;
                    }

                    // Stat-specific filters
                    if (selectedStat === "TotalCards") {
                        // Count total number of collected cards (including all parallels) for the selected team
                        if (cardData[card.cardNumber]) {
                            return cardData[card.cardNumber].collectedCards.reduce((total, collectedCard) => {
                                return total + (matchesTeam ? collectedCard.cardQty : 0);
                            }, 0) > 0;
                        }
                        return false;
                    } else if (selectedStat === "Completion") {
                        return true;
                    } else if (selectedStat === "Parallels") {
                        return cardData[card.cardNumber]?.collectedCards.some(c => c.cardParallelName !== "BASE");
                    } else if (selectedStat === "Numbered") {
                        return cardData[card.cardNumber]?.collectedCards.some(c => c.cardPrintNumber);
                    } else if (selectedStat === "Duplicates") {
                        return cardData[card.cardNumber]?.collectedCards.some(c => c.cardQty > 1);
                    }

                    // Default filter: match team and search
                    return matchesTeam && matchesSearch;
                })
                .sort((a, b) => {
                    const getNumericPart = (cardNumber) => {
                        const parts = cardNumber.split('-');
                        const lastPart = parts[parts.length - 1];
                        return parseInt(lastPart.replace(/^\D+/g, ''), 10) || 0;
                    };
                    return getNumericPart(a.cardNumber) - getNumericPart(b.cardNumber);
                });
        };

        return isToggled
            ? filterAndSortCards(Object.keys(checklistData).map(cardNumber => {
                const checklistCard = checklistData[cardNumber];
                const collectedCard = cardData[cardNumber];
                return collectedCard
                    ? { ...collectedCard, availableParallels: checklistCard.availableParallels || [], isCollected: true }
                    : { ...checklistCard, collectedCards: [], isCollected: false, opacity: 0.5 };
            }))
            : filterAndSortCards(Object.values(cardData));
    }, [isToggled, selectedTeam, selectedStat, enteredSearchTerm, cardData, checklistData]);

    const handleToggleAddParallel = useCallback((cardNumber) => {
        setActiveCardNumber(prev => (prev === cardNumber ? null : cardNumber));
    }, []);

    const handleStatBoxFilterClick = (statType) => {
        switch (statType) {
            case "TotalCards":
                setIsToggled(false);
                setSelectedStat("TotalCards");
                break;
            case "Completion":
                setIsToggled(true);
                setSelectedStat("Completion");
                break;
            case "Parallels":
                setIsToggled(false);
                setSelectedStat("Parallels");
                break;
            case "Numbered":
                setIsToggled(false);
                setSelectedStat("Numbered");
                break;
            case "Duplicates":
                setIsToggled(false);
                setSelectedStat("Duplicates")
                break;
            default:
                setIsToggled(true);
                setSelectedStat(null);
                break;
        }
    }

    const handleToggleClick = () => {
        switch (selectedStat) {
            case "TotalCards":
                setIsToggled(true);
                setSelectedStat("Completion");
                break;
            case "Completion":
                setIsToggled(false);
                setSelectedStat("TotalCards");
                break;
            case "Parallels":
                setIsToggled(false);
                setSelectedStat("Parallels");
                break;
            case "Numbered":
                setIsToggled(false);
                setSelectedStat("Numbered");
                break;
            case "Duplicates":
                setIsToggled(false);
                setSelectedStat("Duplicates")
                break;
            default:
                setIsToggled(true);
                setSelectedStat(null);
                break;
        }
    }

    const fadeInFromRight = {
        hidden: {
            opacity: 0,
            x: 100, // Start 100px to the right
        },
        visible: {
            opacity: 1,
            x: 0, // Animate to its original position
            transition: {
                duration: 0.2,
                ease: "easeOut"
            }
        }
    };

    return (
        <>
            {cardData && (
                <SeriesDisplayContainer>
                    <DisplayHeader className='panel-bg dropshadow'>
                        <h3>{selectedTeam} Collection Insights</h3>
                        <CollectionStats>
                            <div onClick={() => handleStatBoxFilterClick("TotalCards")}>
                                <StatBox
                                    isActive={selectedStat === "TotalCards"}
                                    label={"Collected Cards"}
                                    value={teamFilteredStats.totalCollectedTeamCards}
                                />
                            </div>
                            {!showcaseView &&
                                <div onClick={() => handleStatBoxFilterClick("Completion")}>
                                    <StatBox
                                        isActive={selectedStat === "Completion"}
                                        label={"Completion"}
                                        value={`${teamFilteredStats.collectedTeamCards} of ${teamFilteredStats.totalTeamCards} (${completionPercentage}%)`}
                                    />
                                </div>
                            }
                            <div onClick={() => handleStatBoxFilterClick("Parallels")}>
                                <StatBox
                                    isActive={selectedStat === "Parallels"}
                                    label={"Parallels"}
                                    value={teamFilteredStats.parallelCardsCount}
                                />
                            </div>
                            <div onClick={() => handleStatBoxFilterClick("Numbered")}>
                                <StatBox
                                    isActive={selectedStat === "Numbered"}
                                    label={"Numbered"}
                                    value={teamFilteredStats.numberedCardsCount}
                                />
                            </div>
                            <div onClick={() => handleStatBoxFilterClick("Duplicates")}>
                                <StatBox
                                    isActive={selectedStat === "Duplicates"}
                                    label={"Duplicates"}
                                    value={teamFilteredStats.duplicatesCount}
                                />
                            </div>
                        </CollectionStats>
                    </DisplayHeader>
                    <BodyContainer>
                        <ChecklistControls>
                            <ControlContent>
                                <InputField
                                    type='text'
                                    placeholder='SEARCH...'
                                    value={enteredSearchTerm}
                                    onChange={e => setEnteredSearchTerm(e.target.value)}
                                />
                                {!showcaseView &&
                                    <ToggleContainer onClick={handleToggleClick}>
                                        <ToggleSwitch $isToggled={isToggled}>
                                            <ToggleCircle $isToggled={isToggled} />
                                        </ToggleSwitch>
                                        <span>Display Uncollected Cards</span>
                                    </ToggleContainer>
                                }
                            </ControlContent>

                            <ControlContent>
                                <label htmlFor="teamFilter">Filter By:</label>
                                <select
                                    id="teamFilter"
                                    value={selectedTeam}
                                    onChange={(e) => setSelectedTeam(e.target.value)}
                                    className='panel-bg'
                                >
                                    {teams.map((team, index) => (
                                        <option key={index} value={team}>
                                            {team}
                                        </option>
                                    ))}
                                </select>
                            </ControlContent>
                        </ChecklistControls>
                        <CardsDisplayContainer>
                            <CardsDisplay $width={queuedCardsDisplay.length !== 0 ? '58%' : '100%'}>
                                {filteredCards.length > 0 &&
                                    filteredCards.map((card, index) => (
                                        <div
                                            key={index}
                                            style={{ opacity: card.isCollected ? 1 : card.opacity || 1 }}
                                        >
                                            <ChecklistItemDisplay
                                                showcaseView={showcaseView}
                                                card={card}
                                                isAddingParallel={activeCardNumber === card.cardNumber}
                                                parallels={availableParallels}
                                                onToggleAddParallel={() => handleToggleAddParallel(card.cardNumber)}
                                            />
                                        </div>
                                    ))}
                            </CardsDisplay>
                            {queuedCardsDisplay.length !== 0 &&
                                <QueuedCardsContainer
                                    $fixed={isFixed}
                                    initial="hidden"
                                    animate="visible"
                                    variants={fadeInFromRight}
                                >
                                    <QueuedCards />
                                </QueuedCardsContainer>
                            }
                        </CardsDisplayContainer>
                    </BodyContainer>
                </SeriesDisplayContainer>
            )}
        </>
    );
}