import styled from "styled-components";

export const DreamTeamContainer = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
`
export const FieldDisplayContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    width: 55%;
`
export const OutlineContainer = styled.div`
    width: 100%;
    
    img {
        opacity: 0.2;
        width: 100%;
    }
`
export const PlacedPlayerCard = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
`
export const CardGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
        font-family: 'Roboto Condensed';
        font-size: 24px;
        opacity: 0.8;
    }
`
export const BullpenDisplayContainer = styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 45%;
`
export const BullpenDisplay = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 80px;
`
export const BullpenCardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: 448px;
`