import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectTeamIndex } from '../../store/user/user.selector.js';
import { selectSystemNotification } from '../../store/system/system.selector.js';
import { AnimatePresence } from 'framer-motion';
import { setPreselectedCardId, setPreselectedCardType, setSelectedCardId } from '../../store/showcase/showcase.action.js';

// data
import { GRADIENT_DATA } from '../../data/theme-data/gradient-data.js';

// styles
import {
  MainContainer,
  MainGradientBGContainer,
  NavContainer,
  NavItem
} from './SiteContainer.styles.js';

// components
import GradientBG from '../../components/gradient-bg/GradientBG.js';
import Header from '../../components/header/Header.js';
import Notification from '../../components/notification/Notification.js';

export default function ParentPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const teamIndex = useSelector(selectTeamIndex);
  const systemNotification = useSelector(selectSystemNotification);
  const [activeNavItem, setActiveNavItem] = useState("/")

  const handleNavigate = (path) => {
    if (path === "/") {
      dispatch(setPreselectedCardId(null));
      dispatch(setPreselectedCardType(null));
      dispatch(setSelectedCardId(null));
    }
    setActiveNavItem(path);
    navigate(path);
  }

  return (
    <MainContainer>
      <Header acctTab={true} logOutBtn={true} acctStats={true} />
      <AnimatePresence>
        {systemNotification && <Notification text={systemNotification} key="notification" />}
      </AnimatePresence>
      <NavContainer>
        <NavItem $isActive={activeNavItem === "/add"} onClick={() => handleNavigate("/add")}>+ ADD CARD</NavItem>
        <NavItem $isActive={activeNavItem === "/"} onClick={() => handleNavigate("/")}>HOME</NavItem>
        <NavItem $isActive={activeNavItem === "/collection"} onClick={() => handleNavigate("/collection")}>COLLECTION</NavItem>
        <NavItem $isActive={activeNavItem === "/card-shop"} onClick={() => handleNavigate("/card-shop")}>CARD SHOP</NavItem>
        <NavItem $isActive={activeNavItem === "/checklist-manager"} onClick={() => handleNavigate("/checklist-manager")}>CHECKLIST MANAGER</NavItem>
        <NavItem $isActive={activeNavItem === "/activity-log"} onClick={() => handleNavigate("/activity-log")}>ACTIVITY LOG</NavItem>
      </NavContainer>
      <Outlet />
      <MainGradientBGContainer>
        <GradientBG setGradient={GRADIENT_DATA[teamIndex]} />
      </MainGradientBGContainer>
    </MainContainer>
  )
}
