import PropTypes from 'prop-types';

// styles
import {
    CardDisplayLargeContainer,
    ImgContainer,
    LeftOverlayContent,
    RightOverlayContent,
    BottomOverlayContent
} from './CardDisplayLarge.styles';

// assets
import pullVaultIcon from '../../../../../assets/system/cardvault-symbol.png';

// components
import CardAttribute from '../../../../card-attribute/CardAttribute';
import PrimaryBtn from '../../../../btns/primary-btn/PrimaryBtn';

export default function CardDisplayLarge({ onClick, cardData }) {
    const {
        name = [],
        team = [],
        cardNumber = "",
        cardAttributes = [],
        cardParallel = {}
    } = cardData || {}

    return (
        <CardDisplayLargeContainer
            onClick={onClick}
            whileHover={{ scale: 1.025 }}
            transition={{ type: "spring", stiffness: 240, damping: 20 }}
        >
            <LeftOverlayContent>
                {name.map((playerName) => {
                    return <h1 key={playerName}>{playerName.toUpperCase()}</h1>
                })}
                {team.map((teamName) => {
                    return <CardAttribute key={teamName} size='L' text={teamName} />
                })}
            </LeftOverlayContent>
            <ImgContainer>
                <img src={pullVaultIcon} alt="no card" />
            </ImgContainer>
            <RightOverlayContent>
                <CardAttribute text={cardNumber} size='L' />
                {cardAttributes.length !== 0 &&
                    <CardAttribute text={cardAttributes} size='L' />
                }
                <CardAttribute color={cardParallel.cardColor} text={cardParallel.cardPrintNumber ? `${cardParallel.cardParallelName} (${cardParallel.cardPrintNumber}/${cardParallel.cardSupply})` : cardParallel.cardParallelName} size={'L'} />
            </RightOverlayContent>
            <BottomOverlayContent>
                <PrimaryBtn title={"UPLOAD IMAGE"} />
                <PrimaryBtn title={"SAVE"} />

            </BottomOverlayContent>
        </CardDisplayLargeContainer>
    )
}

CardDisplayLarge.propTypes = {
    id: PropTypes.string.isRequired,
    positionLabel: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};
