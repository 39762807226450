import { SHOWCASE_ACTION_TYPES } from "./showcase.types.js";

const SHOWCASE_INITIAL_STATE = {
    preSelectedCardId: null,
    preSelectedCardType: null,
    selectedCardId: null,
    newSelectionCardData: {
        name: [],
        cardPath: [],
        cardParallel: {
            cardColor: '',
            cardParallelName: '',
            cardPrintNumber: null,
            cardSupply: 0,
        }
    },
    dreamTeamCardData_MLB: {
        "field-DH": {
            id: "field-DH",
            label: "DH",
            name: ["Shohei Ohtani"],
            team: ["Los Angeles Dodgers"],
            cardNumber: "USC50",
            cardAttributes: ["Rookie"],
            cardPath: [
                'MLB',
                '2024',
                'Topps',
                'Topps Chrome® Baseball Update Series',
                'Base Card Set',
                'Base Cards Image Variation'
            ],
            cardParallel: {
                cardColor: "#0FC8EF",
                cardParallelName: "AQUA/BLUE",
                cardPrintNumber: 1,
                cardQty: 1,
                cardSupply: 175
            }
        },
        "field-P": {
            id: "field-P",
            label: "P",
            name: ["Felix Hernandez"],
            team: ["Seattle Mariners"],
            cardNumber: "34",
            cardAttributes: [],
            cardPath: [
                'MLB',
                '2012',
                'Topps',
                'Series 2',
                'Base Card Set',
                'Base'
            ],
            cardParallel: {
                cardColor: "#FFFFFF",
                cardParallelName: "RAINBOW FOIL",
                cardPrintNumber: null,
                cardQty: 1,
                cardSupply: null
            }
        },
        "field-C": {
            id: "field-C",
            label: "C",
            name: ["Joe Mauer"],
            team: ["Minnesota Twins"],
            cardNumber: "34",
            cardAttributes: ["Rawlings Gold Glove Award"],
            cardPath: [
                'MLB',
                '2009',
                'Topps',
                'Series 1',
                'Base Card Set',
                'Base'
            ],
            cardParallel: {
                cardColor: "#FFD700",
                cardParallelName: "GOLD",
                cardPrintNumber: 101,
                cardQty: 1,
                cardSupply: 2009
            }
        },
        "field-1B": {
            id: "field-1B",
            label: "1B",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-2B": {
            id: "field-2B",
            label: "2B",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-3B": {
            id: "field-3B",
            label: "3B",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-SS": {
            id: "field-SS",
            label: "SS",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-LF": {
            id: "field-LF",
            label: "LF",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-CF": {
            id: "field-CF",
            label: "CF",
            name: null,
            team: null,
            cardAttributes: null
        },
        "field-RF": {
            id: "field-RF",
            label: "RF",
            name: null,
            team: null,
            cardAttributes: null
        },
        "rotation-SP1": {
            id: "rotation-SP1",
            label: "SP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "rotation-SP2": {
            id: "rotation-SP2",
            label: "SP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "rotation-SP3": {
            id: "rotation-SP3",
            label: "SP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "rotation-SP4": {
            id: "rotation-SP4",
            label: "SP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "rotation-SP5": {
            id: "rotation-SP5",
            label: "SP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-LRP1": {
            id: "bullpen-LRP1",
            label: "LRP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-LRP2": {
            id: "bullpen-LRP2",
            label: "LRP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-MRP1": {
            id: "bullpen-MRP1",
            label: "MRP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-MRP2": {
            id: "bullpen-MRP2",
            label: "MRP",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-SU1": {
            id: "bullpen-SU1",
            label: "SU",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-SU2": {
            id: "bullpen-SU2",
            label: "SU",
            name: null,
            team: null,
            cardAttributes: null
        },
        "bullpen-CL": {
            id: "bullpen-CL",
            label: "CL",
            name: null,
            team: null,
            cardAttributes: null
        }
    }
};

export const showcaseReducer = (state = SHOWCASE_INITIAL_STATE, action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_ID:
            return { ...state, preSelectedCardId: payload };
        case SHOWCASE_ACTION_TYPES.SET_PRESELECTED_CARD_TYPE:
            return { ...state, preSelectedCardType: payload };
        case SHOWCASE_ACTION_TYPES.SET_SELECTED_CARD_ID:
            return { ...state, selectedCardId: payload };
        case SHOWCASE_ACTION_TYPES.SET_NEW_SELECTION_CARD_DATA:
            return { ...state, newSelectionCardData: payload };
        default:
            return state;
    }
};