import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAvailableYears } from '../../../utils/firebase/firebase.utils';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function YearSelection({ type }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { sport } = useParams();
  const [availableYears, setAvailableYears] = useState([]);

  useEffect(() => {
    const fetchYears = async () => {
      const years = type === "add" ? await getAvailableYears(null, sport) : await getAvailableYears(user.uid, sport);
      setAvailableYears(years);
    };

    fetchYears();
  }, [type, user.uid, sport]);

  const basePath = `/${sport}`;

  let selectedBasePath;
  if (type === "select") {
    selectedBasePath = `/showcase-add${basePath}`;
  } else if (type === "add") {
    selectedBasePath = `/add${basePath}`;
  } else if (type === "view") {
    selectedBasePath = `/collection${basePath}`;
  }

  const displayData = Object.keys(availableYears)
    .map(yearKey => ({
      name: yearKey,
      count: availableYears[yearKey].cardCount || 0
    }))
    .sort((a, b) => b.name - a.name);

  const handleItemClick = () => {
    navigate(selectedBasePath);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={selectedBasePath} />;
}