import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectNewSelectionCardData } from '../../../../../store/showcase/showcase.selector';

// styles
import {
    NewCardSelectionContainer,
    ContentContainer,
    PanelRowTitle,
    OutletContainer
} from './NewCardSelection.styles';

export default function NewCardSelection() {
    const navigate = useNavigate();
    const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();
    const newSelectionCardData = useSelector(selectNewSelectionCardData)

    const handleNavigate = (level) => {
        let path = '/collection';

        switch (level) {
            case 'sport':
                path = '/showcase-add';
                break;
            case 'year':
                path = `/showcase-add/${sport}`;
                break;
            case 'manufacturer':
                path = `/showcase-add/${sport}/${year}`;
                break;
            case 'series':
                path = `/showcase-add/${sport}/${year}/${manufacturer}`;
                break;
            case 'cardType':
                path = `/showcase-add/${sport}/${year}/${manufacturer}/${series}`;
                break;
            case 'cardTypeCategory':
                path = `/showcase-add/${sport}/${year}/${manufacturer}/${series}/${cardType}`;
                break;
            default:
                break;
        }
        navigate(path);
    };
    return (
        <NewCardSelectionContainer>
            <ContentContainer>
                {sport && !newSelectionCardData &&
                    <PanelRowTitle>
                        {sport && (
                            <h4 onClick={() => handleNavigate('sport')}>{sport}</h4>
                        )}
                        {year && (
                            <h4 onClick={() => handleNavigate('year')}>{year}</h4>
                        )}
                        {manufacturer && (
                            <h4 onClick={() => handleNavigate('manufacturer')}>{manufacturer}</h4>
                        )}
                        {series && (
                            <h4 onClick={() => handleNavigate('series')}>{series}</h4>
                        )}
                        {cardType && (
                            <h4 onClick={() => handleNavigate('cardType')}>{cardType}</h4>
                        )}
                        {cardTypeCategory && (
                            <h4 onClick={() => handleNavigate('cardTypeCategory')}>{cardTypeCategory}</h4>
                        )}
                    </PanelRowTitle>
                }
                <OutletContainer>
                    <Outlet />
                </OutletContainer>
            </ContentContainer>
        </NewCardSelectionContainer>
    )
}
