// styles
import {
    CardShopContainer
} from "./CardShop.styles";

// components
import Title from "../../components/widgets/components/title/Title";

export default function CardShop() {
    return (
        <CardShopContainer>
            <Title title={"CARD SHOP"} btnPath={{ title: "+ NEW LISTING", path: ""}}/>
        </CardShopContainer>
    )
}
