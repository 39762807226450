import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/user/user.selector';
import { getAvailableSports } from '../../../utils/firebase/firebase.utils';

// components
import SelectionPanel from '../../selection-panel/SelectionPanel';

export default function SportSelection({ type }) {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const params = useParams();
  const [availableSports, setAvailableSports] = useState([]);

  useEffect(() => {
    const fetchSports = async () => {
      const sports = type === "add" ? await getAvailableSports() : await getAvailableSports(user.uid);
      setAvailableSports(sports);
    };

    fetchSports();
  }, [type, user.uid]);

  const basePath = Object.values(params).map(param => `/${param}`).join('');

  let selectedBasePath;
  if (type === "select") {
    selectedBasePath = `/showcase-add${basePath}`;
  } else if (type === "add") {
    selectedBasePath = `/add${basePath}`;
  } else if (type === "view") {
    selectedBasePath = `/collection${basePath}`;
  }

  const displayData = availableSports.map(sport => ({
    name: sport.sport,
    count: sport.totalCardCount
  }));

  const handleItemClick = () => {
    navigate(selectedBasePath);
  };

  return <SelectionPanel data={displayData} onItemClick={handleItemClick} basePath={selectedBasePath} />;
}