import styled from "styled-components";

export const ShowcaseContainer = styled.div`
    display: flex;
    height: 700px;
    justify-content: space-between;
    width: 100%;
`
export const TitleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0px;
    position: absolute;
    left: 16px;
    top: 16px;

    h1 {
        font-family: 'Roboto Condensed';
        font-size: 40px;
        opacity: 0.8;
    }
`