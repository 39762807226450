import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addCardToQueue, addCardToQueueDisplay } from '../../store/card/card.action';
import { selectQueuedCards, selectQueuedCardsDisplay } from '../../store/card/card.selector';
import { removeCardFromFirestore } from '../../utils/firebase/firebase.utils';
import { selectCardCount, selectUser } from '../../store/user/user.selector';
import { setNewSelectionCardData } from '../../store/showcase/showcase.action';

// styles
import {
    ChecklistItemDisplayContainer,
    CardDetails,
    TopCardContent,
    LeftCardContent,
    Spacer,
    CardNumber,
    QuantityControlsContainer,
    QtyContent,
    CardHeader,
    AddBtn,
    AttributesContainer,
    ParallelConfiguration,
    Parallels,
    Parallel,
    SelectedParallelContainer,
    ParallelDetailsContainer,
    ActionContainer,
    DeselectButton,
    InputContainer,
    InputField,
    ExpandVariations,
    VariationRows,
    VariationRow
} from './ChecklistItemDisplay.styles';

// assets
import addIcon from '../../assets/system/icons/add-icon.png';

// components
import CardAttribute from '../card-attribute/CardAttribute';

export default function ChecklistItemDisplay({ card, parallels = [], isAddingParallel, onToggleAddParallel, showcaseView = false }) {
    const navigate = useNavigate();
    const { sport, year, manufacturer, series, cardType, cardTypeCategory } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const cardCount = useSelector(selectCardCount);
    const [parallelWasSelected, setParallelWasSelected] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const activeQty = 1;

    const queuedCards = useSelector(selectQueuedCards);
    const queuedCardsDisplay = useSelector(selectQueuedCardsDisplay);
    const [variationsExpanded, setVariationsExpanded] = useState(false);
    const { name, cardAttributes, cardNumber, collectedCards, quantity } = card;

    useEffect(() => {
        if (!isAddingParallel) {
            setParallelWasSelected(null);
            setInputValue('');
        }
        if (isAddingParallel) {
            setVariationsExpanded(false);
        }
    }, [isAddingParallel]);

    useEffect(() => {
        if (!parallelWasSelected) {
            setInputValue('');
        }
    }, [parallelWasSelected]);

    const handleAddBaseCardToQueue = (card) => {
        const newCard = {
            ...card,
            collectedCards: [
                {
                    cardParallelName: 'BASE',
                    cardColor: "#FFFFFF",
                    cardQty: activeQty,
                    cardPrintNumber: null,
                    cardSupply: null
                }
            ]
        }
        dispatch(
            addCardToQueue(
                queuedCards,
                sport,
                year,
                manufacturer,
                series,
                cardType,
                cardTypeCategory,
                newCard,
                activeQty
            )
        );
        dispatch(
            addCardToQueueDisplay(
                queuedCardsDisplay,
                sport,
                year,
                manufacturer,
                series,
                cardType,
                cardTypeCategory,
                newCard,
                activeQty
            )
        );
    };

    const handleAddParallelToQueue = (card) => {
        setVariationsExpanded(false);
        if (inputValue === "" && parallels[parallelWasSelected].limitedTo) {
            return;
        } else {
            let selectedParallel = parallels[parallelWasSelected];
            let parallelCard = null;
            if (parallels[parallelWasSelected].limitedTo) {
                parallelCard = {
                    ...card,
                    collectedCards: [{
                        cardParallelName: `${selectedParallel.name}`,
                        cardColor: `${selectedParallel.color}`,
                        cardQty: activeQty,
                        cardPrintNumber: parseInt(inputValue, 10),
                        cardSupply: selectedParallel.limitedTo
                    }]
                };
            } else {
                parallelCard = {
                    ...card,
                    cardAttributes: [...card.cardAttributes],
                    collectedCards: [{
                        cardParallelName: `${selectedParallel.name}`,
                        cardColor: `${selectedParallel.color}`,
                        cardQty: activeQty,
                        cardPrintNumber: null,
                        cardSupply: null
                    }]
                };
            };
            dispatch(
                addCardToQueue(
                    queuedCards,
                    sport,
                    year,
                    manufacturer,
                    series,
                    cardType,
                    cardTypeCategory,
                    parallelCard,
                    activeQty
                )
            );
            dispatch(
                addCardToQueueDisplay(
                    queuedCardsDisplay,
                    sport,
                    year,
                    manufacturer,
                    series,
                    cardType,
                    cardTypeCategory,
                    parallelCard,
                    activeQty
                )
            );
            setParallelWasSelected(null);
            setInputValue('');
            onToggleAddParallel(null);
        }
    };

    const handleParallelClick = (index) => {
        setVariationsExpanded(false);
        setParallelWasSelected(index);
    };

    const handleDeselectParallel = () => {
        setParallelWasSelected(null);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        const maxValue = parallels[parallelWasSelected].limitedTo;

        if (/^\d*$/.test(value)) {
            const numericValue = parseInt(value, 10);
            if (numericValue >= 1 && numericValue <= maxValue) {
                setInputValue(value);
            } else if (value === '') {
                setInputValue('');
            }
        }
    };

    const handleRemoveCard = (card, parallel, amount) => {
        removeCardFromFirestore(user.uid, parallel, amount, sport, year, manufacturer, series, cardType, cardTypeCategory, cardNumber, cardCount, card);
    };

    const handleClick = () => {
        if (collectedCards?.length > 0) {
            if (!isAddingParallel) {
                setVariationsExpanded(prevState => !prevState);
            }
        }
    };

    const handleShowcaseCardAdd = (variation) => {
        const newSelectionData = {
            cardAttributes: card.cardAttributes,
            cardNumber: card.cardNumber,
            name: card.name,
            team: card.team,
            cardPath: [sport, year, manufacturer, series, cardType, cardTypeCategory],
            cardParallel: variation
        };
        dispatch(setNewSelectionCardData(newSelectionData));
        navigate("selection-view");
    }

    return (
        <ChecklistItemDisplayContainer $isCollected={collectedCards?.length > 0} onClick={handleClick} className='panel-bg dropshadow'>
            <TopCardContent>
                <LeftCardContent>
                    <CardNumber>
                        <h4>{cardNumber}</h4>
                    </CardNumber>
                    <CardDetails>
                        <CardHeader>
                            {name.map((playerName, index) => (
                                <span key={index}>
                                    <h3>{playerName}</h3>
                                    {index < name.length - 1 && ' • '}
                                </span>
                            ))}
                        </CardHeader>
                        {(cardAttributes?.length > 0 || collectedCards?.length > 0) &&
                            <AttributesContainer>
                                {cardAttributes?.length > 0 &&
                                    cardAttributes.map(attribute => (
                                        <CardAttribute key={attribute} text={attribute} />
                                    ))
                                }
                                {cardAttributes?.length > 0 && collectedCards?.length > 0 &&
                                    <h4>•</h4>
                                }
                                {collectedCards?.length > 0 &&
                                    <ExpandVariations>
                                        {`${collectedCards?.length} ${collectedCards?.length === 1 ? 'variation' : 'variations'} collected ${variationsExpanded ? '↑' : '↓'}`}
                                    </ExpandVariations>
                                }
                            </AttributesContainer>
                        }
                    </CardDetails>
                </LeftCardContent>
                <QuantityControlsContainer>
                    <QtyContent>
                        {collectedCards && quantity && !showcaseView &&
                            <h4>QTY: {quantity}</h4>
                        }
                        {!isAddingParallel && !showcaseView &&
                            <AddBtn $isActive={true} onClick={() => handleAddBaseCardToQueue(card, activeQty)}>
                                <h4>ADD BASE</h4>
                            </AddBtn>
                        }
                        {parallels?.length !== 0 && !showcaseView &&
                            <AddBtn $isActive={true} onClick={onToggleAddParallel}>
                                <h4>{!isAddingParallel ? 'ADD PARALLEL' : 'CANCEL'}</h4>
                            </AddBtn>
                        }
                    </QtyContent>
                </QuantityControlsContainer>
            </TopCardContent>

            {variationsExpanded &&
                <VariationRows>
                    <>
                        <div style={{ borderTop: "1px solid rgba(255,255,255,0.25)", marginBottom: "8px" }} />
                        {card.collectedCards && card.collectedCards.map((parallel) => (
                            <VariationRow key={parallel.cardParallelName}>
                                <LeftCardContent>
                                    <Spacer />
                                    <CardAttribute color={parallel.cardColor} text={parallel.cardPrintNumber ? `${parallel.cardParallelName} (${parallel.cardPrintNumber}/${parallel.cardSupply})` : parallel.cardParallelName} size={'L'} />
                                </LeftCardContent>
                                <QtyContent>
                                    {!showcaseView &&
                                        <h4>QTY: {parallel.cardQty}</h4>
                                    }
                                    {!showcaseView &&
                                        <AddBtn $isActive={true} onClick={() => handleRemoveCard(card, parallel.cardParallelName, 1)}>
                                            <h4>REMOVE ONE</h4>
                                        </AddBtn>
                                    }
                                    {!showcaseView &&
                                        <AddBtn $isActive={true} onClick={() => handleRemoveCard(card, parallel.cardParallelName, parallel.cardQty)}>
                                            <h4>REMOVE ALL</h4>
                                        </AddBtn>
                                    }
                                    {showcaseView &&
                                        <AddBtn $isActive={true} onClick={() => handleShowcaseCardAdd(parallel)}>
                                            <h4>{'SELECT CARD'}</h4>
                                        </AddBtn>
                                    }
                                </QtyContent>
                            </VariationRow>
                        ))
                        }
                    </>
                </VariationRows>
            }
            {isAddingParallel && (
                <ParallelConfiguration>
                    {parallelWasSelected === null ? (
                        <Parallels>
                            {parallels.map((parallel, index) => (
                                <Parallel
                                    key={parallel.name}
                                    $parallelColor={parallel.color}
                                    onClick={() => handleParallelClick(index)}
                                    className={index === parallelWasSelected ? 'active' : ''}
                                >
                                    {parallel.name}
                                </Parallel>
                            ))}
                        </Parallels>
                    ) : (
                        <SelectedParallelContainer>
                            <ParallelDetailsContainer>
                                <ActionContainer>
                                    <DeselectButton onClick={handleDeselectParallel}>
                                        <img src={addIcon} alt="deselect parallel" style={{ transform: 'rotate(45deg)' }} />
                                    </DeselectButton>
                                    <Parallel
                                        className="active"
                                        $parallelColor={parallels[parallelWasSelected].color}
                                    >
                                        {parallels[parallelWasSelected].name}
                                    </Parallel>
                                    {parallels[parallelWasSelected].limitedTo && (
                                        <InputContainer>
                                            <InputField
                                                type="text"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                                placeholder={"CARD #"}
                                            />
                                            <h4>/ {parallels[parallelWasSelected].limitedTo}</h4>
                                        </InputContainer>
                                    )}
                                </ActionContainer>
                                <AddBtn $isActive={inputValue !== '' || !parallels[parallelWasSelected].limitedTo} onClick={() => handleAddParallelToQueue(card)}><h4>ADD</h4></AddBtn>
                            </ParallelDetailsContainer>
                        </SelectedParallelContainer>
                    )}
                </ParallelConfiguration>
            )}
        </ChecklistItemDisplayContainer>
    );
}