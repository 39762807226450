import styled from "styled-components";

export const NewCardSelectionContainer = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;
`
export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 100%;
    width: 100%;
`
export const PanelRowTitle = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;

    h4 {
        background-color: rgba(255,255,255,0.2);
        border: 2px solid rgba(255,255,255,0.2);
        border-radius: 100px;
        color: #FFFFFF;
        cursor: pointer;
        padding: 4px 12px;

        &:hover {
            background-color: #FFFFFF;
            color: #000000;
        }
    }
`
export const OutletContainer = styled.div`
    height: 100%;
    overflow-y: scroll;
`