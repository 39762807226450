import styled from "styled-components";

export const PrimaryBtnContainer = styled.button`
    border: none;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    height: 32px;
    width: 100%;

    &:hover {
        background: rgba(255,255,255,0.4);
    }
`